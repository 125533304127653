
#day, #date{
    padding: 5px;
    margin: 5px;
}
span {
    margin: 5px;
}

.taskCircles{
  display: flex;
}

.text_input{
  margin-left: 45%;
  margin-top: 70px;
  height: 40px;
  width: 250px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #4d4d4d;
}

.circles{
  display: flex;
}

button{
  cursor: pointer;
}
.emptyCircle, .fullCircle, .halfCircle{
  margin: 15px;
}

.emptyCircle {
  width: 44px;
  display: inline-block;
  height: 44px;
  background-color: white;
  border: 1px solid #DFDFDF;
  border-radius: 50%;
  border-style: solid;
  cursor: pointer;
}

.fullCircle {
  display: inline-block;
  width: 44px;
  height: 44px;
  background-color: #DFDFDF;
  border-radius: 50%;
  border: 1px solid #DFDFDF;
  border-style: solid;
  cursor: pointer;
}


.halfCircle{
    display: inline-block;
    width: 44px; height: 44px;
    border-radius: 50%;
    border: 1px solid #DFDFDF;
    overflow: hidden;
    cursor: pointer;
  }
  
  .one{
    background-color: #FFFFFF;
    height: 22px; width:44px;
  }
  
  .two{
    background-color: #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    height: 22px; width:50px;
    overflow: hidden;
    -ms-transform: rotate(-20deg); /* IE 9 */
    transform: rotate(-20deg);
  }


  .task{
    background: #FDFDFD;
    border-radius: 7px;  
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #696868;
    text-align: center;
  }
  .task:hover{
    background-color: #f6f5f5;
  }

  .task, .empty_div{
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    height: 48px;
    width: 237px;
    margin: 20px 50px 10px 200px;
  }

  .name_update{
    margin: 20px 50px 10px 200px;
    font-family: Poppins;
    font-style: normal;
    letter-spacing: 00.07em;
    font-weight: lighter;
    font-size: 20px;
    padding: 5px;
    line-height: 36px;

  }

  .empty_div{
    visibility: hidden;
  }

  .group, .days_row {
    display: flex;
    align-items: center;
  } 

  .heading{
    width: 123px;
    height: 35px;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }

  .days, .dates{
    display: flex;
    justify-content: center;
  }

  .day, .date{
    font-family: Roboto;
    font-style: normal;
    font-weight: 200;
    font-size: 23px;
    color: #000000;
    overflow: hidden;
    height: 43px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .day{
    background: #FFFFFF;
    border-radius: 0px 0px 5px 5px;
  }

  .date{
    background-color: #E5E5E5;
  }

  .delete{
    margin-left: 30px;
    width: 80px;
    height: 35px;
    background: #FDFDFD;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 7px; 
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #9e9d9d;
  }
  .delete:hover{
    background-color: #ececec;
  }

 .header{
   display: flex;
   margin: 20px;
   text-align: center;
   justify-content: space-between;
   align-items: center;
 }

 .arrow_rect{
    width: 66px;
    height: 53px;
    border: 1px solid #bebdbd;;
    box-sizing: border-box;
    border-radius: 7px; 
    background-color: white;
    font-size: 35px;
    display: inline-block;
    color: #bebdbd;
    margin-left: 100px;
    margin-right: 100px;
 }

 .arrow_rect:hover{
   background-color: #e2e2e2;
 }

 .add{
  width: 237px;
  height: 48px;
  background: #FDFDFD;
  border: 1px solid #999999;
  box-sizing: border-box;
  border-radius: 7px; 
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  padding: 5px;
  letter-spacing: 00.1em;
  margin: 70px 50px 10px 45%;
  letter-spacing: 0.05em;
  color: #a5a2a2;
}

  .add:hover{
    background-color: #eeeeee;
  }

 

  input{
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    width: 200px;
    height: 30px;
  }

  .pack{
    text-align: center;
    width: 44px;
    height: 86px;
    background: #E7E7E7;
    border: 1px solid #B7B7B7;
    border-radius: 5px; 
    margin: 15px;
    float: left;
  }

  .line{
    height: 40px;
    border-left: 1px solid #C8C8C8; 
    display: inline-block;
    margin-top: 1%; 
  }

 .transfer{
    width: 340px;
    height: 48px;
    text-align: center;
    justify-content: space-evenly;
    background: #FDFDFD;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 7px;
    display: flex;
    margin: 20px 50px 100px 42%;
  }

  .export, .import{
    width: 150px;
    height: 48px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #737373;
    display: inline-block;
    text-align: center;
    align-items: center;
    padding-top: 2%;
  }

  #files {
    display: none;
  }	
  
  .import:hover {
    cursor: pointer;
  }

   hr {
    width: 96%;               
    border: 1px solid #F0F0F0;
  }

  .oneDay{
    display: flex;
  }


  .vertical_dotted_line
  {
    border-left: 1px dashed #D2D2D2; 
    height: 100%;
    display: inline-block;
  }

.solid_line  {
    border-left: 1px solid #e2e2e2; 
    height: 100%;
    display: inline-block;
  }